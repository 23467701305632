// *
// * Erdmann & Freunde - Nutshell
// * Starterkit
// * erdmann-freunde.de/nutshell/
// *
// * Lizenziert unter MIT OPEN SOURCE 
// *

//
// COMPONENTS/TABLES
// --------------------------------------------------

// VARIABLES
$table-border-width: 0;
$table-head-border: 3px solid rgba($color-brand-secondary, 0.2);
$table-body-border: 1px solid rgba($color-brand-secondary, 0.2);

// USED FROM _variables.scss

// --------------------------------------------------

@import "../../../../nutshell/scss/components/tables";

// --------------------------------------------------

.ce_table {
  @extend .table--overflow;
  
  font-size: $base-font-size--xl;
  font-family: $base-font-family-sans-serif;
  
  table {
    margin-bottom: 0;
  }
  
  thead {
    background: transparent;
    color: $color-brand-secondary;
    font-weight: 400;
    
    th {
      border-top: $table-head-border;
      border-bottom: $table-head-border;
      white-space: nowrap;
    }
  }
  
  tbody {
    th,
    td {
      border-bottom: $table-body-border;
    }
    
    th {
      font-weight: normal;
      text-align: left;
    }
  }
}
