// *
// * Erdmann & Freunde - Nutshell
// * Starterkit
// * erdmann-freunde.de/nutshell/
// *
// * Lizenziert unter MIT OPEN SOURCE 
// *

//
// COMPONENTS/NAVIGATION
// --------------------------------------------------

// VARIABLES
  $color-navigation-main--active:   	$color-text--inverted;
  $nav--main__item-padding:         	$base-spacing-unit;
  $nav--main__item-padding--sm:     	$base-spacing-unit--xs;
  $nav--main-font:                  	$base-font-family-sans-serif;
  $nav--main-font-weight:           	300;
  
  $submenu-background:								$color-brand-secondary-alpha;
  
  $nav-mobile-color-background:       $color-brand-secondary-gradient;
  $nav-mobile-number-of-menu-points:  4;
  
  $menu-icon-width:										2rem;
  $menu-icon-stroke:									4px;
  

// USED FROM _variables.scss
// $color-text--inverted
// $base-spacing-unit, --xs, --sm
// $base-border
// $base-font-family-sans-serif

// USED FROM _layout.scss
// $header-height

// USED FROM _grid.scss
// $grid__gutter--half

// --------------------------------------------------

@import "../../../nutshell/scss/components/navs";

// --------------------------------------------------

// Main Navigation

// [1] IE9 needs this to make the whole anchor clickable and lets the dropdown-navigation work
// [2] 9999px make sure, the max-height is bigger than the actual submenu height, percentage will reference the header height
// [3] needed for last menu point, to not cut the background square on hover 
.nav--main {
  font-size: 1.11rem;
  line-height: 1em;
  font-family: $nav--main-font;
  font-weight: $nav--main-font-weight;
  display: flex;
  flex: 1 1 auto;
  color: $color-text--inverted;
  
  @include media-query(screen-offcanvas) {
    overflow: visible; // [3]
    margin-right: $base-spacing-unit--lg;
  }
  
  @include media-query(screen-xl) {
    margin-right: $base-spacing-unit--lg;
  }
  
  ul.level_1 {
    display: flex;
    flex: 1 1 auto;
    justify-content: flex-end;
    flex-direction: row;
  }
  
  ul.level_1 > li {
    display: flex;
    flex-direction: column;
    justify-content: center;
    
    
  }
  
  a:not(.invisible),
  span.active,
  span.trail,
  strong.active,
  strong.trail {
    padding: $nav--main__item-padding;
    text-transform: uppercase;
    text-decoration: none; 
    position: relative;
    display: flex;
    flex: 1 1 auto;
    
    > span {
      align-self: center;
    }
  }
  
  ul.level_1 > li {
    position: relative;
    
    &:hover,
    &.active,
    &.trail,
    &.nav--open {
      > a,
      > strong.active,
      > strong.trail {
        &:before {
          background: $color-brand-secondary;
          content: '';
          display: block;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          position: absolute;
          z-index: -1;
          
          
          @include media-query(screen-offcanvas) {
            clip-path: polygon(1rem 0,100% 0,calc(100% - 1rem) 100%,0 100%);
            right: -$base-spacing-unit--sm;
            left: -$base-spacing-unit--sm;
          
          }
        }
      }
    }
  }
  
  a {
    color: inherit;
  }
  
  span.active,
  span.trail,
  strong.active,
  strong.trail {
    color: $color-navigation-main--active;
    cursor: pointer;
  }
  
  ul.level_1 > li.menu-dk {
    @include media-query(screen-offcanvas) {
      position: absolute;
      right: 0;
      height: 100%;
      overflow: hidden;
      
      > a,
      > strong {
        padding: 0;
        background: url(/files/theme/dist/img/icon-flagge-dk.svg) no-repeat center center;
        width: 2rem;
        height: 100%;
        display: block;
        transform: translateX(10%);
        transition: transform 0.2s;
        
        &:hover {
          transform: translateX(0);
        }
      }
      
      &:hover,
      &.active {
        a:before,
        strong:before {
          display: none;
        }
      }

      span {
        display: none;
      }
    }
  }

  
  .level_2 {
	  z-index: 1004;
	  overflow: hidden;
	  max-height: 0;
	  
	  @include media-query(screen-offcanvas) {
		  visibility: hidden;
		  opacity: 0;
		  position: absolute;
      top: 100%;
		  padding: 0 $nav--main__item-padding;
		  margin-left: -$nav--main__item-padding - $base-spacing-unit--sm;
		  transition: opacity 0.2s 0.3s, max-height 0s 0.6s, visibility 0s 0.6s;
	  }
	  
	  > li {
		  float: none;
		  padding: 0;
		  
		  @include media-query(screen-offcanvas) {
		  	background: $submenu-background;
		  	
		  	&:hover {
  			  background: $color-brand-secondary;
			  }
		  }
	  }
	  
	  a,
	  span.active,
	  span.trail,
	  strong.active,
	  strong.trail {
	    padding: $nav--main__item-padding $nav--main__item-padding;
	    margin: 0;
	    border-bottom: 1px solid rgba(255,255,255,0.2);
	  }
	  
	  strong.active,
	  strong.trail {
  	  background: $color-brand-secondary;
	  }
	  
	  @include media-query(screen-offcanvas-max) {
  	  a,
  	  strong.active {
    	  padding-left: $base-spacing-unit--lg;
    	  background: rgba(0,0,0,0.3);
    	  
    	  &:before {
      	  content: '•';
      	  padding-right: $base-spacing-unit--sm;
    	  }
  	  }
  	  
  	  strong.active {
    	  font-style: italic;
  	  }
	  }
	  
	  @include media-query(screen-offcanvas) {		  
		  > li.last {
			  
			  a {
  			  border-bottom: 0;
			  }
		  }
		  
		  a {
  		  padding: $nav--main__item-padding $nav--main__item-padding;
			  color: $color-text--inverted;
		  }
		}
  }
  
  //li.submenu:hover .level_2,
  li.nav--open .level_2 {	 
    max-height: 9999em; 
	  z-index: 1004;
	  display: block;
	  opacity: 1;
	  transition-delay: 0s;
	  visibility: visible;
	}
	
	li.menu-contact .level_2 {
  	@include media-query(screen-offcanvas) {
      right: 0;
      margin-left: 0;
      margin-right: -0.5rem;	
    }
    
  }
}

// [1] transition for mobile navigation fade in background
// [2] transition for mobile navigation item moving
// [3] remove scrollbar for IE9 as it doesn't support -ms-overflow-style
.nav--mobile {
  @include media-query(screen-offcanvas-max) {
    visibility: hidden;
    transition: background 0.3s; // [1]
    background: rgba(255,255,255,0);
    position: fixed;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    font-size: 1.5rem;
    display: block;
    flex: none;
    
    > ul.level_1 {
      position: relative;
      max-height: 100%;
      background: $nav-mobile-color-background;
      overflow-y: scroll;
      -webkit-overflow-scrolling: touch;
      -ms-touch-action: touch;
      -ms-overflow-style: none;
      transform: translateY(-100%);
      flex-direction: column;
      
      transition: transform 0.3s;
    }
    
    > ul.level_1 > li {
    transition: transform 0.3s, opacity 0.3s; // [2]
    opacity: 0; // [2]
    
    
      @include media-query(screen-offcanvas) {
        opacity: 1;
      }
    }
    
    a,
    strong.active {
      border-bottom: 1px solid rgba(255,255,255,0.2);
	    color: $color-text--inverted;
	    padding: $base-spacing-unit $base-spacing-unit;
    }
  }
  
  .level_2 {
    z-index: -1;
  }
  
  li.nav--open {
    .level_2 {
      max-height: 9999px;
    }
  }
  
}

.nav--mobile--active {
  @include media-query(screen-offcanvas-max) {
    background: $color-white-alpha;
    z-index: 1003;
    visibility: visible;
    
    > ul.level_1 {
      transform: translateY(0%);
      justify-content: flex-start;
      flex: 1 0 auto;
    }
  
    >ul.level_1 > li {
      opacity: 1;
    }
    
    .level_2 {

    }
  }
}

// button to display mobile navigation
.nav-toggler {
  font-family: sans-serif;
  float: right;
  position: absolute;
  top: $base-spacing-unit--sm;
  right: $grid__gutter--half;
  z-index: 1004;
  
  @include media-query(screen-lg) {
    top: 1.5rem;
  }

  @include media-query(screen-offcanvas) {
    display: none;  
  }   
}

.nav-toggler__button {
	//@include vertical-align(50%);
  
  background: none;
  border: 0;
  outline: none; 
  font-weight: 700;
  display: inline-block;
  margin: 0;
  cursor: pointer;
  color: $color-brand-secondary;
  padding: $base-spacing-unit--xs/2 $base-spacing-unit--xs;
}

.menu-icon {
	position: relative;
	width: $menu-icon-width;
	height: $base-line-height * 2rem;
	display: inline-block;
	vertical-align: top;
}

.menu-icon__inner {
	width: 100%;
	background-color: currentcolor;
	position: absolute;
	left: 0;
	top: 50%;
	margin-top: $menu-icon-stroke/-2;
  height: $menu-icon-stroke;
  transition: transform 0.15s ease;

	&:after, 
	&:before {
	  background-color: currentcolor;
	  position: absolute;
	  display: block;
		content: "";
    width: 100%;
		height: $menu-icon-stroke;
    transition: transform 0.15s ease, opacity 0.15s;
	}
	
	&:before {
		top: -8px;
	}
	
	&:after {
	  bottom: -8px;
	}
	
	.html--fixed & {
		transform: rotate(45deg);
		
		&:before {
			opacity: 0;
		}
		
		&:after {
			transform: rotate(-90deg);
			bottom: 0;
		}
	}
}

.pagination {
  font-family: $base-font-family-sans-serif;
  width: 100%;
  
  p {
    display: none;
  }
  
  p + ul {
    float: none;
  }
  
  span.active,
  a {
    padding: $base-spacing-unit--sm $base-spacing-unit calc(#{$base-spacing-unit--sm} - 2px);
    background-image: linear-gradient(to bottom, #adadad, #cccccc);
    border-bottom: 4px solid #bfbfbf;
    color: $color-text--inverted;
  }
  
  span.active {
    background: $color-brand-secondary-gradient;
    border-left: 0;
    border-top: 0;
    border-right: 0;
    border-bottom: 4px solid $color-brand-secondary;
  }
  
  a.previous {
    padding-left: $base-spacing-unit;
  }
  
  a.next {
    padding-right: $base-spacing-unit;
  }
}


.nav--meta {
  @include make-col(12);
  
  padding-top: $base-spacing-unit--lg;
  padding-bottom: $base-spacing-unit--lg;
  
  a {
    color: $color-text--inverted;
  }
  
  @include media-query(screen-sm) {
    @include make-col(6);
    
    li {
      text-align: right;
    }
  }
  
  @include media-query(screen-md) {
    @include make-col(3);
    
    float: right;
  }
  
  @include media-query(screen-lg) {
    @include make-col(2);
  }
}
