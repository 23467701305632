// *
// * Erdmann & Freunde - Nutshell
// * Starterkit
// * erdmann-freunde.de/nutshell/
// *
// * Lizenziert unter MIT OPEN SOURCE 
// *

//
// COMPONENTS/LINKS
// --------------------------------------------------

// VARIABLES
$button-border-radius: 0;
$button-bg:                     $color-brand-primary-gradient;
$button-bg-hover:               $color-brand-primary-gradient;
$button-spacing:                $base-spacing-unit--sm $base-spacing-unit calc(#{$base-spacing-unit--sm} - 2px);
$button-border-width:           4px;

$btn-font-family: $base-font-family-sans-serif;
$btn-font-weight: 300;

$btn--secondary-color:					$color-text--inverted;
$btn--secondary-bg:             $color-brand-secondary-gradient;

$btn--primary-highlight-bg:			$color-text;
$btn--primary-highlight-text: 	$color-text--inverted;

$btn--secondary-highlight-text:	$color-text--inverted;

// USED FROM _variables.scss
// $base-spacing-unit--xs, --sm, --xl
// $base-border-radius
// $base-border-width
// $base-border

// --------------------------------------------------

@import "../../../nutshell/scss/components/links";

// --------------------------------------------------

a {
  [id="footer"] & {
    color: inherit;
  }
}

%button {
  font-family: $btn-font-family;
  font-weight: $btn-font-weight;
  font-size: 1.25em;
  line-height: 1.25;
  letter-spacing: 0.01875rem;
  text-transform: uppercase;
  border-radius: $button-border-radius;
  border: 0;
}

// btn--primary
a.btn--primary,
span.btn--primary,
button.btn--primary {
  border-bottom: $button-border-width solid $color-brand-primary;
  
	p & {
		margin-bottom: 0;
		text-decoration: none;
	}
}

// [1] button used in a .band--highlight container
div.btn--primary {
    
  a {
    border-bottom: $button-border-width solid $color-brand-primary;
    
    .band--highlight & { // [1]
      background: $btn--primary-highlight-bg;
      color: $btn--primary-highlight-text;
    }
    
    [id="footer"] & {
      background: $color-text--inverted;
      color: $color-brand-secondary;
      border-bottom: 0;
    }  
  }
}

a.btn--secondary,
span.btn--secondary,
button.btn--secondary {
  color: $btn--secondary-color;
  background: $btn--secondary-bg;
  border-bottom: $button-border-width solid $color-brand-secondary;
  
  &:hover {
    background-color: transparent;
  }
  
  .band--highlight & {
    color: $btn--secondary-highlight-text;
    border-color: $btn--secondary-highlight-text;
    
    &:hover {
      opacity: 0.5; 
    }
  }
}

div.btn--secondary {
  
  a {
    color: $btn--secondary-color;
    background: $btn--secondary-bg;
    border-bottom: $button-border-width solid $color-brand-secondary;
    
    .band--highlight & {
      color: $btn--secondary-highlight-text;
      border-color: $btn--secondary-highlight-text;
      
      &:hover {
        opacity: 0.5; 
      }
    }
  }
}

div.btn--small > a {
  font-size: $base-font-size--xs;
  padding: ($base-spacing-unit--sm * 2/3) $base-spacing-unit--sm ;
}

div.btn--feedback {
	position: fixed;
	right: 0;
	top: $base-spacing-unit--xl;
	transform: rotate(-90deg) translateY($base-spacing-unit--xs);
	transform-origin: bottom right;
	transition: transform 0.1s;
	
	&:hover {
		transform: rotate(-90deg) translateY(0);
	}
	
	a {
		padding: $base-spacing-unit--xs $base-spacing-unit--sm;
		border-bottom-left-radius: 0;
		border-bottom-right-radius: 0;
		border: $base-border;
		border-bottom: 0;
	}
}
