// *
// * Erdmann & Freunde - Nutshell
// * Starterkit
// * erdmann-freunde.de/nutshell/
// *
// * Lizenziert unter MIT OPEN SOURCE 
// *

//
// COMPONENTS/HERO
// --------------------------------------------------

// VARIABLES
$hero__color-background: $color-gray-dark;
$hero__color-text:       $color-text--inverted;
$hero__font-family:      $base-font-family-sans-serif;
$hero__font-weight:      700;

// USED FROM _variables.scss
// $base-spacing-unit, --lg

// USED FROM _layout.scss
/// $header-height

// --------------------------------------------------

// hero
// [1] every .inside container has position relative by contao default
// [2] calculate height based on padding for mod_article, including fallback
// [3] flexbox fix for IE10 & IE11, see http://caniuse.com/#feat=flexbox

.mod_article.hero {
  @include wrapper-max(none);
  
  background: $hero__color-background;
  color: $hero__color-text;
  position: relative;
  padding-left: 0;
  padding-right: 0;
  z-index: 0;
  
  min-height: 85vh; // [2]
  min-height: calc(85vh - #{2 * $base-spacing-unit--lg}); // [2]
  height: inherit;
  
    
  > .inside {
    @include padding-default;
    @include wrapper-max;

    position: static; // [1]
    min-height: inherit;
    overflow: hidden;
    display: flex;
    flex: 0 0 auto;
  }
  
  &--full {
    min-height: 100vh; // [2]
    min-height: calc(100vh - #{2 * $base-spacing-unit--lg}); // [2]
    height: inherit;
    
    > .inside {
      .ie10 &,
      .ie11 & {
      	height: calc(100vh - #{2 * $base-spacing-unit--lg}); // [2][3]
      }
    }

    
    @media screen and (min-height: 800px) {
    	min-height: 90vh; // [2]
    	min-height: calc(90vh - #{2 * $base-spacing-unit--lg}); // [2]

    	
    	> .inside {
      	.ie10 &,
      	.ie11 & {
      	  height: calc(90vh - #{2 * $base-spacing-unit--lg}); // [2][3]
        }
    	}
    }
  }
  
  &--sm {
    min-height: 0;
    padding-top: 10vh;
    padding-bottom: 10vh;
    
    @include media-query(screen-offcanvas) {
      padding-top: 20vh;
      padding-bottom: 20vh;
    }
  }
}

// [1] IE10 calculating wrong widths, when max-width isn't set
.hero__text {   
  position: relative;
  z-index: 1000;
  text-align: center;
  align-self: center;
  font-family: $base-font-family-sans-serif;
  font-size: 1.25em;
  font-weight: 300;
  letter-spacing: 0.01875rem;
  flex: 1 1 auto;
  
  h2 {
    color: $color-text--inverted;
    font-style: italic;
    
	  @include media-query(screen-xs-max) {
		  
	  }
  }
  
  &--left {
    text-align: left;
  }
  
  &--right {
    text-align: right;
  }
  
  &--top {
    top: 0;
    align-self: flex-start;

  }
  
  &--bottom {
    bottom: 0;
    align-self: flex-end;
    margin-bottom: 0;
  }
  
  .ie10 & {
	  max-width: 100%; // [1]
  }
}

.hero__image {
  position: static;
  
  .image_container {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
}

.hero__map {
  [id=map] {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
  }
}
