// *
// * Erdmann & Freunde - Nutshell
// * Starterkit
// * erdmann-freunde.de/nutshell/
// *
// * Lizenziert unter MIT OPEN SOURCE 
// *

//
// COMPONENTS/FORMS
// --------------------------------------------------

// VARIABLES
$input-border-width: 1px;

// USED FROM _variables.scss

// --------------------------------------------------

@import "../../../../nutshell/scss/components/forms";

// --------------------------------------------------

// PUT YOUR OWN STYLES HERE

.contact__form {
  label {
    @extend .invisible;
  }
  
  input.text,
  input.captcha,
  textarea {
    font-family: $base-font-family-sans-serif;
  }
  
  button.submit {
    @extend button.btn--secondary;
  }
}

.widget-captcha {
  padding-bottom: $base-spacing-unit;
}
