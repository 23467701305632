// *
// * Erdmann & Freunde - euf_timeline
// *

//
// COMPONENTS/TIMELINE
// --------------------------------------------------

// VARIABLES
									

$timeline-border-color: 				rgba($color-brand-secondary, 0.3);
$timeline-border-width: 				4px;

$timeline-year-bg:							$color-brand-secondary;
$timeline-year-color:						#fff;
$timeline-content-width:				40%;
$timeline-content-bg:						#fff;
$timeline-content-border-color:	transparent;
$timeline-content-border-width: 0;


// USED FROM _variables.scss

// --------------------------------------------------

.timeline {
  position: relative;
  
  
}

.timeline__element,
.timeline__element * {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.timeline__element {
  @extend %clearfix;
  
  position: relative;
  padding-top: $base-spacing-unit--lg;
  padding-bottom: $base-spacing-unit--lg;
  
  @include media-query(screen-lg) {
  
    &:before {
      content: '';
      position: absolute;
  		left: 50%;
  		margin-left: -2px;
  		height: 100%;
  		bottom: 0;
      width: $timeline-border-width;
      background: $timeline-border-color;
    }
    
    &:first-child:before {
      height: 50%;
      top: 50%;
    }
    
    &:last-child:before {
      height: 50%;
      top: 0;
    }
  }
  
  @supports(display: flex) {
    &:after {
      display: none;
    }
  }
  
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
		font-size: 1.25rem;
  }
  
  @include media-query(screen-lg) {
    padding-top: $base-spacing-unit--xl;
		padding-bottom: $base-spacing-unit--xl;
		display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.timeline__year {
	display: none;
	margin-bottom: 0.5em;  
  width: 1.5rem;
  height: 1.5rem;
  background: $timeline-year-bg;
  color: $timeline-year-color;
  border: $timeline-border-width solid $timeline-border-color;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
	z-index: 1001;
	left: 50%;
	transform: translateX(-50%);
	-webkit-transform: translateX(-50%);
	-ms-transform: translateX(-50%);
  
  @include media-query(screen-lg) {
		position: absolute;
		display: inline-block;
  }
}

.timeline__content {
	margin-bottom: 1em;
  position: relative;
  border-radius: 4px;
  overflow: hidden;
  background: $timeline-content-bg;
  
  @include media-query(screen-lg) {
  	float: left;
		width: $timeline-content-width;
  }
  
  .image_container.float_above {
	  margin-bottom: 0.5em;
  }
}

.timeline__element .image_container {
  @include media-query(screen-lg) {
  	float: right;
		width: $timeline-content-width;
  }
}

.timeline__element--right {
	
  @include media-query(screen-lg) {
	  .timeline__content {
	    float: right;
	    order: 1;
	  }
  }
  
  @include media-query(screen-lg) {
    .image_container {
      float: left;
    }
  }
}

@keyframes animation-bounce-in-left {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-100px);
  }
  60% {
    opacity: 1;
    -webkit-transform: translateX(20px);
  }
  100% {
    -webkit-transform: translateX(0);
  }
}
@keyframes animation-bounce-in-right {
  0% {
    opacity: 0;
    -webkit-transform: translateX(100px);
  }
  60% {
    opacity: 1;
    -webkit-transform: translateX(-20px);
  }
  100% {
    -webkit-transform: translateX(0);
  }
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  60% {
    opacity: 1;
  }
}

// animation for animated timeline
.animate {
	.timeline__content,
	.timeline__year {
	  animation-fill-mode: forwards;
	}

	.animated .timeline__year {
		animation: fade-in 0.6s;
	}

	.timeline__element--left .timeline__content {
	  animation: animation-bounce-in-left 0.6s;
	}

	.timeline__element--right .timeline__content {
	  animation: animation-bounce-in-right 0.6s;
	}
}
