// *
// * Erdmann & Freunde - Nutshell
// * Starterkit
// * erdmann-freunde.de/nutshell/
// *
// * Lizenziert unter MIT OPEN SOURCE 
// *

//
// COMPONENTS/NEWS
// --------------------------------------------------

// VARIABLES
$news-grid-columns--sm: 2;
$news-grid-columns--md: 2;
$news-grid-columns--lg: 3;

// USED FROM _variables.scss

// --------------------------------------------------

@import "../../../../nutshell/scss/components/news";

// --------------------------------------------------

// PUT YOUR OWN STYLES HERE

// [1] prevent gap between more button and border-bottom
.news-grid {
  display: flex;
  flex-wrap: wrap;
  
  .layout_short, .layout_latest, .layout_simple {
    position: relative;
    display: flex;
    flex: 0 1 auto;
    flex-direction: column;	
    margin-top: $base-spacing-unit--lg;
    margin-bottom: $base-spacing-unit--lg;
  }
  
  h3 {
    position: absolute;
    z-index: 1;
    max-width: percentage(9/12);
    color: $color-text--inverted;
    padding: $base-spacing-unit--sm $base-spacing-unit;
    overflow: hidden;
    
    a {
      color: inherit;
      display: inline-block;
      
      &:after {
        content: '';
        background: $color-brand-secondary-alpha;
        transform: rotate(6deg);
        transform-origin: top right;
        position: absolute;
        z-index: -1;
        left: 0%;
        right: 0;
        top: 0%;
        bottom: -100%;
      }
    }
  }
  
  .image_container {
    margin-bottom: $base-spacing-unit;
  }
  
  .ce_text {
    text-align: left;
    
    @include media-query(screen-md) {
      margin-bottom: $base-spacing-unit--lg;
    }
  }
  
  .more {
    margin-top: auto;
    text-align: right;
    border-bottom: $base-border;
    
    a {
      @extend %button;
      
      padding-top: $base-spacing-unit--xs;
      padding-bottom: $base-spacing-unit--xs;
      font-size: 1em;
      
      background: $color-brand-secondary;
      display: inline-block;
      color: $color-text--inverted;
      text-decoration: none;
      
      clip-path: polygon(4% 0,101% 0,101% 101%,0 101%);
      margin-bottom: -1px; // [1]
    }
  }
}

// [1] prevent gap between more button and border-bottom
// [2] fix hiding headlines when scrolling the news-carousel
.news-carousel {
  //@include padding-default();
  
  //width: 100%;
  
  @include media-query(screen-sm-max) {
		padding-bottom: $base-spacing-unit;
		width: auto;
		overflow-x: scroll;
		-webkit-overflow-scrolling: touch;
		-ms-touch-action: auto;
	  /* no scrollbar for ie */
	  -ms-overflow-style: none;
		flex-wrap: nowrap;
		scroll-snap-type: mandatory;
	}
  
  .layout_short, .layout_latest, .layout_simple {
    position: relative;
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;    
    flex-shrink: 0;
		scroll-snap-align: start;
		
		@include media-query(screen-sm-max) {
  		width: 100%;
    }
  }
  
  h3 {
    position: absolute;
    z-index: 1;
    max-width: percentage(9/12);
    color: $color-text--inverted;
    padding: $base-spacing-unit--sm $base-spacing-unit;
    overflow: hidden;
    backface-visibility: hidden; // [2]
    
    &:after {
      content: '';
      background: $color-brand-secondary-alpha;
      transform: rotate(6deg);
      transform-origin: top right;
      position: absolute;
      z-index: -1;
      left: 0%;
      right: 0;
      top: 0%;
      bottom: -100%;
    }
    
    a {
      color: inherit;
    }
  }
  
  a {
    overflow: hidden;
  }
  
  .image_container {
    margin-bottom: $base-spacing-unit;
    overflow: hidden;
  }
  
  .ce_text {
    text-align: left;
    
    @include media-query(screen-md) {
      margin-bottom: $base-spacing-unit--lg;
    }
  }
  
  .more {
    margin-top: auto;
    text-align: right;
    border-bottom: $base-border;
    
    a {
      @extend %button;
      
      padding-top: $base-spacing-unit--xs;
      padding-bottom: $base-spacing-unit--xs;
      font-size: 1em;
      overflow: visible;
      
      background: $color-brand-secondary;
      display: inline-block;
      color: $color-text--inverted;
      text-decoration: none;
      
      clip-path: polygon(4% 0,101% 0,101% 101%,0 101%);
      margin-bottom: -1px; // [1]
    }
  }
}

.news-full {
  .layout_full {
    @include make-row();
  }
  
  
  h1 {
    @extend %_h2;
    @include padding-default();

    &:after {
      content: '';
      width: 8rem;
      height: 5px;
      display: block;
      
      margin-right: auto;
      margin-top: $base-spacing-unit--lg;
      margin-bottom: $base-spacing-unit*1.5;
      background: $color-brand-secondary;
    }

    @include media-query(screen-offcanvas) {
      @include make-col(4);
    }  
  }
  
  .content_container {
    @include padding-default();
    
    @include media-query(screen-offcanvas) {
      @include make-col(8);
    } 
    
    > .ce_image,
    > .ce_text,
    > .ce_sliderStart,
    > .ce_youtube,
    > .ce_table {
      margin-bottom: $base-spacing-unit--lg;
    }
  }
  
  .back {
    @include media-query(screen-offcanvas) {
      @include make-width(8);
      @include make-offset(4);
    }
    
    
    border-top: 0;
    margin-top: auto;
    text-align: right;
    border-bottom: $base-border;
    
    a {
      @extend %button;
      
      padding-top: $base-spacing-unit--xs;
      padding-bottom: $base-spacing-unit--xs;
      font-size: 1em;
      
      background: $color-brand-secondary;
      display: inline-block;
      color: $color-text--inverted;
      text-decoration: none;
      
      clip-path: polygon(4% 0,101% 0,101% 101%,0 101%);
      margin-bottom: -1px; // [1]
      
      &:before {
        display: none;
      }
    }
  }
}
