// *
// * Erdmann & Freunde - Nutshell
// * Starterkit
// * erdmann-freunde.de/nutshell/
// *
// * Lizenziert unter MIT OPEN SOURCE 
// *

//
// COMPONENTS/INFO
// --------------------------------------------------

// VARIABLES

// USED FROM _variables.scss

// --------------------------------------------------

.btn-scrolldown {
    position: absolute;
    z-index: 1002;
    left: 50%;
    bottom: $base-spacing-unit--lg;
    transform: translateX(-50%);
}

.info {
  @include padding-default();
  
  [id="footer"] & {
    padding-top: $base-spacing-unit--lg;
    padding-bottom: $base-spacing-unit--lg;
  }
  
  @include media-query(screen-sm-max) {
    border-bottom: 1px solid $color-brand-secondary-light;
  }
}

.info--2 {
  @include media-query(screen-sm) {
    @include make-col(12);
  }
  
  @include media-query(screen-lg) {
    @include make-col(2);
  }
}

.info--3 {
  @include media-query(screen-sm) {
    @include make-col(6);
  }
  
  @include media-query(screen-md) {
    @include make-col(3);
  }
  
  @include media-query(screen-lg) {
    @include make-col(3);
  } 
}

.info--4 {
  @include media-query(screen-sm) {
    @include make-col(6);
  }
  
  @include media-query(screen-md) {
    @include make-col(6);
  }
  
  @include media-query(screen-lg) {
    @include make-col(4);
  } 
}

.info__address,
.info__phone,
.info__fax,
.info__mail {
  font-family: $base-font-family-sans-serif;
  font-size: $base-font-size--xl;
  background-size: 1.25rem auto;
  background-repeat: no-repeat;
  background-position-y: 0.25rem;
  padding-left: 2rem;
}

.info__address {
  background-image: url(/files/theme/dist/img/icon-pin.svg);
  
  [id="footer"] & {
    background-image: url(/files/theme/dist/img/icon-pin-white.svg);
  }
}

.info__phone,
.info__fax {
  margin-bottom: 0;
}

.info__phone {
  background-image: url(/files/theme/dist/img/icon-telefon.svg);
  
  [id="footer"] & {
    background-image: url(/files/theme/dist/img/icon-telefon-white.svg);
  }
}

.info__fax {
  background-image: url(/files/theme/dist/img/icon-fax.svg);
  
  [id="footer"] & {
    background-image: url(/files/theme/dist/img/icon-fax-white.svg);
  }
}

.info__mail {
  background-image: url(/files/theme/dist/img/icon-mail.svg);
  background-position-y: 0.5rem;
  
  [id="footer"] & {
    background-image: url(/files/theme/dist/img/icon-mail-white.svg);
  }
}

.info__opening {
  font-family: $base-font-family-sans-serif;
  font-size: $base-font-size--xl;
  margin-bottom: $base-spacing-unit;
  
  [id="main"] & {
    margin-top: $base-spacing-unit;
    border-top: 1px solid rgba($color-text, 0.2);
    padding-top: $base-spacing-unit;
  }
}

.info__logos {
  margin-bottom: $base-spacing-unit--sm;
  
  @include media-query(screen-md) {
    text-align: center;
  }
  
  img {
    display: inline-block;
    margin: 0 $base-spacing-unit--xs;
  }
}

div.info__btn {
  text-align: left;
}


// cookiebar
#cookiebar {
  z-index: 1006;
  font-family: $base-font-family-sans-serif;
}
