// *
// * Erdmann & Freunde - Nutshell
// * Starterkit
// * erdmann-freunde.de/nutshell/
// *
// * Lizenziert unter MIT OPEN SOURCE 
// *

//
// COMPONENTS/SLIDER
// --------------------------------------------------

// VARIABLES
$arrow-color:               inherit;
$arrow-size:                32px;
$menu-point-color:          inherit;
$menu-point-color--active:  $color-brand;
$arrow-padding:							$base-spacing-unit--sm;

// USED FROM _variables.scss

// --------------------------------------------------

@import "../../../nutshell/scss/components/slider";

// --------------------------------------------------

.slider {
	.slider-wrapper > * {
		margin-bottom: $base-spacing-unit--lg;
	}
	
	.slider-wrapper .row {
		max-width: #{$wrapper-max} !important;
		width: auto !important;
		min-width: auto;
	}
	
	.slider-control {
		
		.slider-prev,
		.slider-next {
			opacity: 0;
			background-repeat: no-repeat;
      background-position: center center;	
      background-size: 20px auto;
      transition: opacity 0.3s 1s;
      
      @include media-query(screen-offcanvas) {
        background-size: 40px auto;
      }
      
      @media screen and (pointer:coarse) {
      	opacity: 0.5;
      }
      
      
      &:before {
        display: none;
      }
		}
		
		a {
			width: calc(#{$arrow-size});
			height: calc(#{$arrow-size});
			overflow: hidden;
			
			@include media-query(screen-sm) {
  			width: calc(#{$arrow-size} + #{$arrow-padding} * 2);
        height: calc(#{$arrow-size} + #{$arrow-padding} * 2);
  		}
		}
		
		.slider-prev {
  		background-image: url(/files/theme/dist/img/icon-pfeil-links-blau.svg);
		}
		
		.slider-next {
  		background-image: url(/files/theme/dist/img/icon-pfeil-rechts-blau.svg);
		}
	}
	
	&:hover {
		.slider-prev,
    .slider-next {
			opacity: 0.5;
			transition: opacity 0.3s;
		}
	}
	
	.slider-menu {
  	display: none;
		font-size: 1rem;
		
		b {
			color: transparent;
			border-radius: 50%;
			background: $color-gray-lighter;
			width: 0.75rem;
			height: 0.75rem;
			padding: 1px;
			border: 2px solid transparent;
			display: inline-block;
			margin: 0 0.2rem;
		}
		
		b.active {
			color: transparent;
			border: $base-border-width solid $color-text;
			background: transparent;
		}
	}
}

.ce_sliderStart {
  @extend .slider;
  
  border-bottom: 5px solid $color-brand-secondary;
  margin-bottom: $base-spacing-unit;
  
  .slider-wrapper > * {
    margin-bottom: 0;
  }
  
  .band--primary-accent & {
    border-bottom-color: $color-brand-primary;
  }
}

// Fullscreen Slider, used in combination with .mod_article.hero
.slider--hero {
  margin-bottom: 0;
  min-height: inherit;
  height: inherit;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  min-height: 85vh; // [2]
  min-height: calc(85vh - #{2 * $base-spacing-unit--lg}); // [2]
  border-bottom: 0;
  
  .ce_image {
    margin-bottom: 0;
  }
  
  .content-slider,
  .slider-wrapper,
  .slider-wrapper > *,
  .image_container {
    min-height: inherit;
    height: 100%;
    
    .image_container {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }
    
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
}

