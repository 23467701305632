// *
// * Erdmann & Freunde - Nutshell
// * Starterkit
// * erdmann-freunde.de/nutshell/
// *
// * Lizenziert unter MIT OPEN SOURCE 
// *

//
// BASE/PAGE
// --------------------------------------------------

// VARIABLES
$font-url:      '../fonts/';

// USED FROM _variables.scss

// --------------------------------------------------

/* merriweather-300 - latin */
@font-face {
  font-family: 'Merriweather';
  font-style: normal;
  font-weight: 300;
  src: url('#{$font-url}merriweather-v19-latin-300.eot'); /* IE9 Compat Modes */
  src: local('Merriweather Light'), local('Merriweather-Light'),
       url('#{$font-url}merriweather-v19-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('#{$font-url}merriweather-v19-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
       url('#{$font-url}merriweather-v19-latin-300.woff') format('woff'), /* Modern Browsers */
       url('#{$font-url}merriweather-v19-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
       url('#{$font-url}merriweather-v19-latin-300.svg#Merriweather') format('svg'); /* Legacy iOS */
}
/* merriweather-300italic - latin */
@font-face {
  font-family: 'Merriweather';
  font-style: italic;
  font-weight: 300;
  src: url('#{$font-url}merriweather-v19-latin-300italic.eot'); /* IE9 Compat Modes */
  src: local('Merriweather Light Italic'), local('Merriweather-LightItalic'),
       url('#{$font-url}merriweather-v19-latin-300italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('#{$font-url}merriweather-v19-latin-300italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('#{$font-url}merriweather-v19-latin-300italic.woff') format('woff'), /* Modern Browsers */
       url('#{$font-url}merriweather-v19-latin-300italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('#{$font-url}merriweather-v19-latin-300italic.svg#Merriweather') format('svg'); /* Legacy iOS */
}
/* merriweather-700 - latin */
@font-face {
  font-family: 'Merriweather';
  font-style: normal;
  font-weight: 700;
  src: url('#{$font-url}merriweather-v19-latin-700.eot'); /* IE9 Compat Modes */
  src: local('Merriweather Bold'), local('Merriweather-Bold'),
       url('#{$font-url}merriweather-v19-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('#{$font-url}merriweather-v19-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('#{$font-url}merriweather-v19-latin-700.woff') format('woff'), /* Modern Browsers */
       url('#{$font-url}merriweather-v19-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('#{$font-url}merriweather-v19-latin-700.svg#Merriweather') format('svg'); /* Legacy iOS */
}
/* merriweather-700italic - latin */
@font-face {
  font-family: 'Merriweather';
  font-style: italic;
  font-weight: 700;
  src: url('#{$font-url}merriweather-v19-latin-700italic.eot'); /* IE9 Compat Modes */
  src: local('Merriweather Bold Italic'), local('Merriweather-BoldItalic'),
       url('#{$font-url}merriweather-v19-latin-700italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('#{$font-url}merriweather-v19-latin-700italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('#{$font-url}merriweather-v19-latin-700italic.woff') format('woff'), /* Modern Browsers */
       url('#{$font-url}merriweather-v19-latin-700italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('#{$font-url}merriweather-v19-latin-700italic.svg#Merriweather') format('svg'); /* Legacy iOS */
}
/* source-sans-pro-300 - latin */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 300;
  src: url('#{$font-url}source-sans-pro-v11-latin-300.eot'); /* IE9 Compat Modes */
  src: local('Source Sans Pro Light'), local('SourceSansPro-Light'),
       url('#{$font-url}source-sans-pro-v11-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('#{$font-url}source-sans-pro-v11-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
       url('#{$font-url}source-sans-pro-v11-latin-300.woff') format('woff'), /* Modern Browsers */
       url('#{$font-url}source-sans-pro-v11-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
       url('#{$font-url}source-sans-pro-v11-latin-300.svg#SourceSansPro') format('svg'); /* Legacy iOS */
}
/* source-sans-pro-900 - latin */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 900;
  src: url('#{$font-url}source-sans-pro-v11-latin-900.eot'); /* IE9 Compat Modes */
  src: local('Source Sans Pro Black'), local('SourceSansPro-Black'),
       url('#{$font-url}source-sans-pro-v11-latin-900.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('#{$font-url}source-sans-pro-v11-latin-900.woff2') format('woff2'), /* Super Modern Browsers */
       url('#{$font-url}source-sans-pro-v11-latin-900.woff') format('woff'), /* Modern Browsers */
       url('#{$font-url}source-sans-pro-v11-latin-900.ttf') format('truetype'), /* Safari, Android, iOS */
       url('#{$font-url}source-sans-pro-v11-latin-900.svg#SourceSansPro') format('svg'); /* Legacy iOS */
}
