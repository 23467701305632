// *
// * Erdmann & Freunde - Nutshell
// * Starterkit
// * erdmann-freunde.de/nutshell/
// *
// * Lizenziert unter MIT OPEN SOURCE 
// *

//
// COMPONENTS/NEWS
// --------------------------------------------------

// VARIABLES

// USED FROM _variables.scss

// --------------------------------------------------

// PUT YOUR OWN STYLES HERE
.carousel {
  @include wrapper-max(calc(#{$wrapper-max} - #{$grid__gutter}));
  position: relative;
  overflow: visible;
  
  .carousel-stage-outer {
    position: relative;
    overflow: hidden;
    -webkit-transform: translate3d(0,0,0);
  }
  
  .carousel-stage {
    display: flex;
    position: relative;
    -ms-touch-action: pan-Y;
    touch-action: manipulation;
    -moz-backface-visibility: hidden;
  }
  
  .owl-drag .owl-item {
    -ms-touch-action: none;
    touch-action: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  
  .owl-item {
    display: flex;
    flex-direction: column;
    min-height: 1px;
    float: left;
    -webkit-backface-visibility: hidden;
    -webkit-touch-callout: none;
  }
  
  .carousel-nav {
    position: absolute;
    z-index: 0;
    top: 50%;
    width: 100%;
    font-family: $base-font-family-sans-serif;
  }
    
  .owl-prev,
  .owl-next {
    position: absolute;
    background: transparent;
    border: 0;
    color: $color-gray;
    font-size: $base-font-size--xl;
    opacity: 0;    
    transition: opacity 0.3s 1s;
    transform: translateY(-50%);
    background-repeat: no-repeat;
    background-position: center center;
    
    @include media-query(screen-offcanvas) {
      left: 0;
      opacity: 0.5; 
    }
    
    @include media-query(screen-xl) {
      opacity: 1;
      font-size: $base-font-size--xl*3;
    }
    
    span {
      opacity: 0;
    }
    
    &.disabled {
      opacity: 0;
      cursor: default;
    }
    
    &:focus {
      outline: none;
    }
  }
  
  .owl-prev {
    left: $base-spacing-unit--sm; 
    background-image: url(/files/theme/dist/img/icon-pfeil-links-blau.svg);
    opacity: 0.5;
    
    
    
    @include media-query(screen-xl) {
      left: -$base-spacing-unit--xl; 
      background-image: url(/files/theme/dist/img/icon-pfeil-links.svg);
    }
  }
  
  .owl-next {
    right: $base-spacing-unit--sm;
    background-image: url(/files/theme/dist/img/icon-pfeil-rechts-blau.svg);
    opacity: 0.5;
    
    @include media-query(screen-offcanvas) {
      left: auto;
      right: 0;
    }
    
    @include media-query(screen-xl) {
      right: -$base-spacing-unit--xl; 
      background-image: url(/files/theme/dist/img/icon-pfeil-rechts.svg);
    }
  }
  
  @include media-query(screen-xl) {
    &:hover {
      .owl-prev,
      .owl-next {
        opacity: 1;
        transition: opacity 0.3s;
      }
    }
  }
  
  &.disabled {
    display: none;
  }
}
