// *
// * Erdmann & Freunde - Nutshell
// * Starterkit
// * erdmann-freunde.de/nutshell/
// *
// * Lizenziert unter MIT OPEN SOURCE 
// *

//
// BASE/GRID
// --------------------------------------------------

// VARIABLES
$debug-mode2: false;
$row-bg:  rgba(0,0,0,0);
$col-bg: rgba(255,0,0,0.1);
$container-bg: rgba($color-page-background,.2);

// USED FROM _variables.scss

// --------------------------------------------------

@import "../../../../nutshell/scss/base/grid";

// --------------------------------------------------

// PUT YOUR OWN GRID STYLES HERE

// [1] IE 11: bug with a flexbox parent and row-child, causing to calculate percentages from the parent
.row {
  min-width: 100%; // [1]
}

.row--flex {
  display: flex;
  flex-wrap: wrap;
  //justify-content: flex-start;

  > * {
    display: flex;
    flex-direction: column;
    //justify-content: flex-start;
  }
  
  .align-bottom {
    margin-bottom: 0;
    margin-top: auto;
  }
}

.row--reverse {
  
  flex-direction: row-reverse;
  justify-content: flex-end;
}

.row--center {
  > * {
    justify-content: center;
    
    > * {
      //margin-bottom: 0;
    }
  }
}

.row--no-gutter {
	margin-left: 0;
	margin-right: 0;
	
	> * {
		padding-left: 0;
		padding-right: 0;
	}
}

@if ($debug-mode2 == true) {
  // Debug Mode
  #wrapper {
  	position: relative;
  	z-index: 1001;
  
    &:before {
	    pointer-events: none;
	    @include wrapper-max(calc(#{$wrapper-max} - #{$grid__gutter}));
	    
	    width: calc(100% - #{$grid__gutter});
	    
	    //padding-left: $grid__gutter--half;
	    //padding-right: $grid__gutter--half;
    	content: "";
    	display: block;
    	position: fixed;
    	z-index: 1005;
    	left: 50%;
    	transform: translateX(-50%);
    	top: 0;
    	height: 100%;
    	background: repeating-linear-gradient(to right,
    		$col-bg 0%, $col-bg calc((100% - (#{$grid__gutter} * #{$grid__gutters})) / #{$grid__columns}), 
    		$row-bg calc((100% - (1.875rem * 11)) / 12), $row-bg calc(((100% - (#{$grid__gutter} * #{$grid__gutters})) / #{$grid__columns}) + #{$grid__gutter})
    	); // [1]
    }
  }
  
  [class*="col"] > div {
    //background: $container-bg;
  }
}
