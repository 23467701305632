// *
// * Erdmann & Freunde - Nutshell
// * Starterkit
// * erdmann-freunde.de/nutshell/
// *
// * Lizenziert unter MIT OPEN SOURCE 
// *

//
// COMPONENTS/LOGO
// --------------------------------------------------

// VARIABLES

// --------------------------------------------------

.logo {
  padding-top: $base-spacing-unit--sm;
  padding-bottom: $base-spacing-unit--sm;
  flex: 0 0 auto;
  
  img {
     margin-left: 0;
     max-width: 12rem;
     transition: max-width 0.3s;
  }
  
  @include media-query(screen-lg) {
    padding-top: $base-spacing-unit;
    padding-bottom: $base-spacing-unit;
    
    img {
      max-width: 100%;
    }
  }
  @include media-query(screen-offcanvas) {
    .header--fixed,
    .header--hidden & {
      img {
        max-width: 10rem;
        transition: max-width 0.3s;
      }
    }
  }
}
