// *
// * Erdmann & Freunde - Nutshell
// * Starterkit
// * erdmann-freunde.de/nutshell/
// *
// * Lizenziert unter MIT OPEN SOURCE 
// *

//
// COMPONENTS/TEXT-IMAGE
// --------------------------------------------------

// VARIABLES

// --------------------------------------------------

.text--image {
  overflow: visible;
  
  @include media-query(screen-md-max) {
    margin-top: -$base-spacing-unit * 1.5;
    margin-bottom: -$base-spacing-unit * 1.5 !important;
  }
  
  
  > .inside {
    @include make-row();
  }
  
  .image_container {
    @include padding-default();

    margin-top: -1 * $base-spacing-unit;
    margin-left: -1 * $grid__gutter--half;
    margin-right: -1 * $grid__gutter--half;
    margin-bottom: 0;
    border-top: $base-border;
    float: none;
    //flex: 1 1 100%;
    //width: percentage(8/12);
    
    .band--primary-accent & {
      border-top-color: $color-brand-primary;
    }
    
    // tablet optimization
    @include media-query(screen-md-max) {
      &.float_left {
        padding-left: 0;
      }
      
      &.float_right {
        padding-right: 0;
      }
    }
  }
  
  .text_container {
    @include padding-default();
     
    line-height: 1.7;
    flex: 1 1 auto;
    
    padding-top: $base-spacing-unit--lg;
    padding-bottom: $base-spacing-unit--xl;
    
    p {
      margin-bottom: $base-spacing-unit--lg;
    }
    
    @include media-query(screen-md) {
      padding-top: $base-spacing-unit--lg;
      padding-bottom: $base-spacing-unit--lg;
    }
  }
  
  @include media-query(screen-md) {
    
    > .inside {     
      display: flex;
      flex-direction: row;
      position: relative;
    }
    
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
    
    .image_container {
      @include make-col(8);
      
      display: block;
      border-top: 0;
      margin: 0;
      position: relative;   
      overflow: hidden; 
      flex-grow: 1;
      
      &:before { 
        transform: rotate(10deg);

        content: '';
        width: 120px;
        height: 110%;
        position: absolute;
        z-index: 1001;
        display: block;
        background: $color-page-background;
        
        .band--tint & {
          background: $color-tint;
        }
      }
      
      &.float_right {
        float: right;
        order: 1;
        
        &:before {
          transform-origin: bottom left;
          right: calc(100% - 10px - #{$grid__gutter--half});
          bottom: 0;
          border-right: 10px solid $color-brand-secondary;
          
          .band--primary-accent & {
            border-color: $color-brand-primary;
          }
        }
      }
      
      &.float_left {
        float: left;
        
        &:before {
          transform-origin: top right;
          left: calc(100% - 10px - #{$grid__gutter--half});
          border-left: 10px solid $color-brand-secondary;
          
          .band--primary-accent & {
            border-color: $color-brand-primary;
          }
        }
      }
    }
    
    .text_container {
      @include make-col(4);
    }
  }
}
