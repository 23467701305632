// *
// * Erdmann & Freunde - Nutshell
// * Starterkit
// * erdmann-freunde.de/nutshell/
// *
// * Lizenziert unter MIT OPEN SOURCE 
// *

//
// BASE/LAYOUT
// --------------------------------------------------

// VARIABLES
$header-gradient-position-1: calc(((100vw - #{$wrapper-max}) / 2) + 10.5rem);
$header-gradient-position-2: calc(((100vw - #{$wrapper-max}) / 2) + 13.5rem);;

// USED FROM _variables.scss

// --------------------------------------------------

@import "../../../../nutshell/scss/base/layout";

// --------------------------------------------------

// PUT YOUR OWN STYLES HERE

// nav--mobile--active
html.html--fixed {
  @include media-query(screen-offcanvas-max) {
    overflow-y: hidden;
    //position: relative;
  }
}

body.background--secondary {
  background: $color-page-background-secondary;
}

// #header
// [1] animation for fixed header
[id="header"] {
  overflow: visible;
  transition: transform 0.3s, position 0s 3s; // [1]
  position: absolute;
  left: 0;
  right: 0;
  z-index: 1001;
  
  
  @include media-query(screen-offcanvas) {
    position: absolute;

    background: linear-gradient(100deg, rgba(#fdfdff,0.6) $header-gradient-position-1, rgba($color-brand-secondary, 0.6) $header-gradient-position-1);
    
  }
  
  @include media-query(screen-lg) {
    background: linear-gradient(100deg, rgba(#fdfdff,0.6) $header-gradient-position-2, rgba($color-brand-secondary, 0.6) $header-gradient-position-2);
  }
  
  > .inside {
    overflow: visible;
    
    @include media-query(screen-offcanvas) {
      display: flex;
    }
  }
  
  .ie &,
  .edge & {
    @include media-query(screen-offcanvas) {
      background: rgba($color-brand-secondary, 0.6)
    }
    
    @include media-query(screen-lg) {
      background: rgba($color-brand-secondary, 0.6)
    }
  }
  
  @include media-query(screen-offcanvas) {
  
    &.header--hidden {
      transform: translateY(-100%);
      position: fixed;
    }
    
    // fixed header
    &.header--fixed {
      transform: translateY(0);
      position: fixed;
      z-index: 1005;
      transition: transform 0.3s, position 0s 3s;
    }
  }
}

// #main
.mod_article {
	padding-top:    $base-spacing-unit * 1.5;
  padding-bottom: $base-spacing-unit * 1.5;
  
  @include media-query(screen-lg) {
    padding-top:    $base-spacing-unit--lg;
    padding-bottom: $base-spacing-unit--lg;
  }
  
  @include media-query(screen-xl) {
    padding-top:    $base-spacing-unit--xl;
    padding-bottom: $base-spacing-unit--xl;
  }
  
  .inside {
	  > .last {
 	    margin-bottom: 0;
	  }
  }
  
  &[id="intro"] {
    @include media-query(screen-md-max) {
      padding-top: $base-spacing-unit--lg;
      padding-bottom: $base-spacing-unit--lg;
    }
  }
}

// footer

[id="footer"] {
  background: $color-brand-secondary-gradient;
  color: $color-text--inverted;
  font-family: $base-font-family-sans-serif;
  border-bottom: 10px solid $color-brand-secondary;
}




// band
.band {
  @include wrapper-max(none);

  padding-left: 0;
  padding-right: 0;
  position: relative;
  
  > .inside {
    @include wrapper-max();
    @include padding-default();
  }
  
  &.article--full .inside {
    @include wrapper-max(none);
  }
}

.band--tint {
  background-color: $color-band-tint-background;
}

.band--primary-accent {
  #{headings()} {
    color: $color-brand-primary;
  }
}
