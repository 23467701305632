// *
// * Erdmann & Freunde - Nutshell
// * Starterkit
// * erdmann-freunde.de/nutshell/
// *
// * Lizenziert unter MIT OPEN SOURCE 
// *

//
// PUT YOUR OWN VARIABLES HERE
// --------------------------------------------------

$color-gray:                      #787878;
$color-gray-lighter:              #f3f4f8;


$color-brand-primary:             #ce1116 !default;
$color-brand-primary-light:       #f24f53 !default;

$color-brand-primary-gradient:    linear-gradient($color-brand-primary 0%, $color-brand-primary-light 100%);
$color-brand-primary-alpha:       rgba($color-brand-primary, 0.8);

$color-brand-secondary:           #17287f !default;
$color-brand-secondary-light:     #263aa0 !default;

$color-brand-secondary-gradient:  linear-gradient($color-brand-secondary 0%, $color-brand-secondary-light 100%);
$color-brand-secondary-alpha:     rgba($color-brand-secondary, 0.8);


$color-brand:                     $color-brand-primary;
$color-tint:                      $color-gray-lighter;

$color-white-alpha:               rgba(255,255,255,0.5);


// Page
$color-page-background:           #fff;
$color-page-background-secondary: #f3f4f8;
$color-text:                      $color-gray;
$color-text--inverted:            $color-page-background;

// Base Font Settings
$base-font-size--xs:      0.875rem;
$base-font-size:          1rem;
$base-font-size--xl:      1.125rem;

$base-line-height:        1.625;

$base-font-family-sans-serif: "Source Sans Pro", "Helvetica Neue", sans-serif;
$base-font-family-serif:      "Merriweather", "Georgia", "Times New Roman", serif;

$base-font-family:            $base-font-family-serif;


$color-band-tint-background:  $color-tint;


// Base Border Settings
$base-border-radius:          4px;
$base-border-width:           2px;
$base-border-color:           $color-brand-secondary;


// --------------------------------------------------

@import "../../../nutshell/scss/variables";

// --------------------------------------------------
