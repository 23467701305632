// *
// * Erdmann & Freunde - Nutshell
// * Starterkit
// * erdmann-freunde.de/nutshell/
// *
// * Lizenziert unter MIT OPEN SOURCE 
// *

//
// COMPONENTS/MEDIA
// --------------------------------------------------

// VARIABLES

// USED FROM _variables.scss

// --------------------------------------------------

@import "../../../../nutshell/scss/components/media";

// --------------------------------------------------

.ce_image {
  position: relative;
  
  .caption {
    position: absolute;
    bottom: 0;
    right: 0;
    padding: $base-spacing-unit--xs $base-spacing-unit--sm $base-spacing-unit--xs $base-spacing-unit;
    font-style: italic;
    font-size: $base-font-size--xs;
    
    background: $color-brand-secondary-alpha;
    display: inline-block;
    color: $color-text--inverted;
    text-decoration: none;
    
    clip-path: polygon(4% 0,101% 0,101% 101%,0 101%);    
  }
}

.image--border-bottom {
  .image_container {
    border-bottom: 5px solid $color-brand-secondary;
    
    .band--primary-accent & {
      border-bottom-color: $color-brand-primary;
    }
  } 
}

// [1] default class to create object-fit images
// [2] add by js for browsers that don't support the object-fit attribute
.image--object-fit .image_container { // [1]
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  
  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}

.compat-object-fit { // [2]
  background-size: cover;
  background-position: center center;
  
  img {
    opacity: 0;
  }
}

.ce_gallery {
  .cols_2 {
    li.col_first img {
      margin-right: 0;
    }
    
    li.col_last img {
      margin-left: 0;
    }
  }
}
