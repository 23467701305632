// *
// * Erdmann & Freunde - Nutshell
// * Starterkit
// * erdmann-freunde.de/nutshell/
// *
// * Lizenziert unter MIT OPEN SOURCE 
// *

//
// COMPONENTS/ACCORDION
// --------------------------------------------------

// VARIABLES
$accordion-background:    $color-page-background;
$accordion-padding:       $base-spacing-unit;

$toggler-font-size:       1.3rem;
$toggler-color:           $color-brand-secondary;
$toggler-background:      $color-page-background;
$toggler-spacing:         $base-spacing-unit;

// USED FROM _variables.scss

// --------------------------------------------------

@import "../../../../nutshell/scss/components/accordion";

// --------------------------------------------------

.ce_accordion {
  
  .toggler {
    font-weight: 700;
    font-family: $base-font-family-sans-serif;
    display: flex;
    flex: 1 1 auto;
    
    &:hover {
      color: $color-gray;
    }
  }
  
  // [1] define size for plus/cross
  .ui-accordion-header-icon {
    align-self: center;
    order: 2;
    margin-right: 0;
    margin-left: auto;
    display: inline-block;
    font-weight: 700;
    font-size: 2rem;
    line-height: 1;

    &::before {
      content: '+';
    }
  }

  .ui-icon-triangle-1-s {
    &::before {
      content: '−';
    }
  }
}
