// *
// * Erdmann & Freunde - Nutshell
// * BASE Contao Framework
// * erdmann-freunde.de/nutshell/
// *
// * Lizenziert unter MIT OPEN SOURCE 
// *

//
// MIXINS/RESPONSIVE
// --------------------------------------------------

// VARIABLES
$wrapper-max:         68.5416666667em;

$additional_breakpoints: (
  offcanvas: (
    min:        81.25em, // 768px
    max:        (81.25em - 0.0625em), // 767px
  )
);

// USED FROM _variables.scss

// --------------------------------------------------

@import "../../../../nutshell/scss/mixins/responsive";

// --------------------------------------------------

// PUT YOUR OWN MIXINS HERE
